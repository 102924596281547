import { createHash } from 'crypto'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';

declare global {
    interface Window {
        fbq: (eventType: string, eventName: string, eventProperties?: object, options?: { eventID: string }) => void
    }
}

type WindowWithDataLayer = Window & {
    dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

interface UserData {
    client_ip_address: string | undefined
    client_user_agent: string | undefined
    em: string
}

interface Event {
    event_name: string
    event_time: number
    action_source: string
    event_id: string
    user_data: UserData
}

interface EventData {
    data: Event[]
}

function sha256(data: string) {
    return createHash('sha256').update(data).digest('hex')
}

const eventMapping = {
    signup: {
        meta: "CompleteRegistration",
        ga: "account_creation"
    },
    login: {
        meta: "Login",
        ga: "login"
    },
};

export function get_fbc_cookie(): string | undefined {
    return Cookies.get('_fbc') ?? undefined
}

export function get_fbp_cookie(): string | undefined {
    return Cookies.get('_fbp') ?? undefined
}


export const trackEvent = async (eventType: keyof typeof eventMapping, email: string | null) => {
    const metaEventName = eventMapping[eventType].meta;
    const gaEventName = eventMapping[eventType].ga;

    console.log('tracking event', eventType, email)
    if (email) {
        //facebook
        const fbc = get_fbc_cookie()
        const fbp = get_fbp_cookie()

        console.log('sending to capi: ', email)
        sendEventToAPI(metaEventName, email, fbc, fbp)

        let hashedEmail: string | undefined = undefined
        try {
            hashedEmail = await sha256(email.toLowerCase())
        } catch (error) {
            console.error('Failed to hash email:', error)
        }

        // ga4
        window.dataLayer = window.dataLayer || []
        const dataLayerObject: { event: string; user_id?: string } = {
            event: gaEventName,
        }
        if (hashedEmail) {
            dataLayerObject.user_id = hashedEmail
        }
        window.dataLayer.push(dataLayerObject)
    }   
}

const sendEventToAPI = async (eventName: string, email: string, fbc?:string, fbp?:string) => {
    const event_uuid = uuidv4()
    const hashedEmail = sha256(email.toLowerCase()) // Hash the email

    try {
        window.fbq('track', eventName, {}, { eventID: event_uuid })
    } catch (error) {
        console.error('Failed to send event to FB:', error)
    }
    // var formatted_phone = phoneNumber;
    // formatted_phone = formatted_phone.replace(/[^\d+]+/g, '');
    // formatted_phone = formatted_phone.replace(/^00/, '+');
    // if (formatted_phone.match(/^1/)) formatted_phone = '+' + formatted_phone;
    // if (!formatted_phone.match(/^\+/)) formatted_phone = '+1' + formatted_phone;

    const eventData: EventData = {
        data: [
            {
                event_name: eventName,
                event_time: Math.floor(Date.now() / 1000),
                action_source: 'website',
                event_id: event_uuid,
                user_data: {
                    client_ip_address: undefined,
                    client_user_agent: undefined,
                    em: hashedEmail, // Include hashed email
                    ...(fbp && { fbp: fbp }),
                    ...(fbc && { fbc: fbc }),
                },
            },
        ],
    }

    try {
        const response = await fetch('/api/capi', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(eventData),
        })

        const data = await response.json()
        console.log(data.message)
    } catch (error) {
        Sentry.captureException(error);
        console.error('Error:', error)
    }
}

export default sendEventToAPI
