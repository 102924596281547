'use client'
import { useContext, createContext, useState, useEffect, ReactNode, SetStateAction, Dispatch } from 'react'
import { onAuthStateChanged, User } from 'firebase/auth'
import { auth } from '@/components/initialize/firebase'
import { fetchSubscription, Subscription, Price, UserPreferenceKey } from '@/components/subscription/subscription_utils'
import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import { get_fbc_cookie, get_fbp_cookie } from '../utils/tracking'
import { getFirstUTMTracking, getUTMTracking } from '../initialize/utmTracker'

// Define the shape of the context
interface AuthContextType {
    user: User | null
    isAuthenticating: boolean
    subscription: Subscription | null
    isSubscriptionLoading: boolean
    hasActiveSubscription: () => boolean
    clearSubscription: () => void
    getSubscription: (refresh?: boolean, user_to_check?: User) => Promise<Subscription | null>
    hasBillingError: () => boolean
    isUpgrade: (prices: Price[] | null, button_price_id: string) => boolean
    isCurrentTier: (button_price_id: string) => boolean
    inTrial: () => boolean
    isCanceled: () => boolean
    setPreference: (preference_name: UserPreferenceKey, preference_value: boolean) => Promise<boolean>
    subscriptionError: boolean
    isVipReady: () => boolean
    isVip: () => boolean
    getCancelDate: () => string | null
    getEndDate: () => string | null
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

interface AuthContextProviderProps {
    children: ReactNode
}

declare global {
    interface Window {
        gtag: (command: string, target: string, action?: string | object, callback?: (clientId: string) => void) => void
    }
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const [user, setUser] = useState<User | null>(null)
    const [isAuthenticating, setAuthenticating] = useState<boolean>(true)
    const [subscription, setSubscription] = useState<Subscription | null>(null)
    const [isSubscriptionLoading, setSubscriptionLoading] = useState<boolean>(true)
    const [subscriptionError, setSubscriptionError] = useState<boolean>(false)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log('Auth State Changed', currentUser)
            setUser(currentUser)
            if (currentUser) {
                // console.log('onAuthStateChanged', currentUser)
                getSubscription(true, currentUser)
                if (currentUser.email) {
                    Sentry.setUser({ email: currentUser.email })
                    console.log('Setting Sentry User to ', currentUser.email)
                } else {
                    console.log('Did not set Sentry User', user)
                }
            } else {
                setSubscriptionLoading(false)
            }
            setAuthenticating(false)

            // console.log("Turning off all loading")
        })
        return () => unsubscribe()
    }, [])

    function get_ga_clientid_cookie(): string | undefined {
        const gaCookie: string | undefined = Cookies.get('_ga')
        if (gaCookie) {
            return gaCookie.substring(6)
        }
        return undefined
    }

    function get_ga_sessionid_cookie(): string | undefined {
        const gaId: string | undefined = process.env.NEXT_PUBLIC_GA_ID
        if (gaId) {
            const gaIdWithoutPrefix: string = gaId.startsWith('G-') ? gaId.slice(2) : gaId
            const sessionCookie: string | undefined = Cookies.get(`_ga_${gaIdWithoutPrefix}`)
            if (sessionCookie) {
                const parts: string[] = sessionCookie.split('.')
                if (parts.length >= 4) {
                    return parts[2]
                }
            } else {
                console.log("get_ga_sessionid_cookie: can't find session cookie: ", `_ga_${gaId}`)
            }
        }
        return undefined
    }

    

    const getSubscription = async (refresh: boolean = false, user_to_check: User | null = user): Promise<Subscription | null> => {
        if (!user_to_check) {
            console.log('getSubscription: no user to check')
            setSubscriptionLoading(false)
            return null
        } else if (subscription && !refresh) {
            console.log('getSubscription: returning cached subscription')
            setSubscriptionLoading(false)
            return subscription
        } else {
            try {
                console.log('getSubscription: Fetching...')
                console.log('getSubscription: Refreshing subscription')
                setSubscriptionLoading(true)
                const token = await user_to_check.getIdToken()
                console.log('getSubscription: Got token')
                if (token) {
                    const fetchedSubscription = await fetchSubscription(token, {
                        ga_client_id: get_ga_clientid_cookie(),
                        ga_session_id: get_ga_sessionid_cookie(),
                        fbc: get_fbc_cookie(),
                        fbp: get_fbp_cookie(),
                        utm_first: getFirstUTMTracking(),
                        utm_current: getUTMTracking(),
                    })
                    console.log('getSubscription: Fetched: ', fetchedSubscription)
                    if (fetchedSubscription === null) {
                        console.log('getSubscription: Error fetching')
                        setSubscriptionError(true)
                        return null
                    } else {
                        setSubscription(fetchedSubscription)
                        setSubscriptionError(false)
                        return fetchedSubscription
                    }
                } else {
                    console.log("getSubscription: couldn't get a token, user_to_check:", user_to_check)
                    return null
                }
            } finally {
                setSubscriptionLoading(false)
            }
        }
    }

    const setPreference = async (preference_name: UserPreferenceKey, preference_value: boolean): Promise<boolean> => {
        if (!user) {
            console.error("Can't set preference, no user data")
            return false
        } else if (!subscription) {
            console.error("Can't set preference, no subscription data")
            return false
        } else {
            try {
                const token = await user.getIdToken()
                const response = await axios.post(
                    `${process.env.NEXT_PUBLIC_API_URL}/update-preferences`,
                    {
                        preference_name,
                        new_value: preference_value,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    },
                )
                console.log('Preference Update Success:', response.data)
                setSubscription({
                    ...subscription,
                    preferences: {
                        ...subscription.preferences,
                        [preference_name]: preference_value,
                    },
                })
                return true
            } catch (error) {
                Sentry.captureException(error)
                console.error('Error updating preference:', error)
                return false
            }
        }
    }

    const clearSubscription = () => {
        console.log('Cleared subscription.')
        setSubscription(null)
    }

    const hasActiveSubscription = () => {
        return !!(subscription?.subscription_id && (subscription?.subscription_status == 'active' || subscription?.subscription_status == 'trialing'))
    }

    const hasBillingError = () => {
        return !!(subscription?.subscription_id && (subscription?.subscription_status == 'past_due' || subscription?.subscription_status == 'unpaid'))
    }

    function isVipReady(): boolean {
        return !!(subscription?.vip_days && !subscription?.is_vip)
    }

    function isVip(): boolean {
        // console.log('isVip: ', !!subscription?.is_vip)
        return !!subscription?.is_vip
    }

    const hasNoSubscription = () => {
        return !!(
            !subscription ||
            subscription?.subscription_status === 'incomplete' ||
            subscription?.subscription_status === 'canceled' || 
            subscription?.subscription_status === 'incomplete_expired' ||
            subscription?.subscription_status === undefined
        )
    }

    function getKeyOrder(key: string): number {
        if (key.startsWith('low_')) return 1
        if (key.startsWith('mid_')) return 2
        if (key.startsWith('high_')) return 3
        return 0 // default order if none of the expected prefixes are found
    }

    const isUpgrade = (prices: Price[] | null, button_price_id: string) => {
        const current_price = prices?.find((x) => x.id === subscription?.subscription_price_id)
        const button_price = prices?.find((x) => x.id === button_price_id)

        if (!current_price || !button_price) {
            return false // or handle the absence of prices as needed
        }

        const currentOrder = getKeyOrder(current_price.lookup_key)
        const buttonOrder = getKeyOrder(button_price.lookup_key)

        return buttonOrder > currentOrder
    }

    const isCurrentTier = (button_price_id: string) => {
        return subscription?.subscription_price_id == button_price_id
    }

    const inTrial = () => {
        return subscription?.subscription_status == 'trialing'
    }

    const isCanceled = () => {
        return subscription?.subscription_status == 'canceled'
    }

    const getCancelDate = (): string | null => {
        const cancelEpoch = subscription?.subscription_cancel_at
        if (!cancelEpoch) return null

        const date = new Date(cancelEpoch * 1000) // Convert seconds to milliseconds
        return date.toLocaleDateString()
    }

    const getEndDate = (): string | null => {
        const endEpoch = subscription?.subscription_end
        if (!endEpoch) return null

        const date = new Date(endEpoch * 1000) // Convert seconds to milliseconds
        return date.toLocaleDateString()
    }

    const contextValues = {
        user,
        isAuthenticating,
        subscription,
        isSubscriptionLoading,
        hasActiveSubscription,
        clearSubscription,
        getSubscription,
        hasBillingError,
        hasNoSubscription,
        isUpgrade,
        isCurrentTier,
        inTrial,
        isCanceled,
        setPreference,
        subscriptionError,
        isVipReady,
        isVip,
        getCancelDate,
        getEndDate
    }

    return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>
}

export const UserAuth = (): AuthContextType | undefined => {
    return useContext(AuthContext)
}
