'use client'

// types/utm.ts
export interface UTMTracking {
    source?: string
    medium?: string
    campaign?: string
    content?: string
}

interface UTMState {
    current: UTMTracking
    first: UTMTracking
}

import { useSearchParams } from 'next/navigation'
import { Suspense, useEffect } from 'react'
import Cookies from 'js-cookie'
import { getDomain } from '@/utils/cookies'

const COOKIE_NAME = 'utmtr'
const COOKIE_EXPIRES = 90 // days
const UTM_PARAMS = ['source', 'medium', 'campaign', 'content'] as const
const MAX_COOKIE_LENGTH = 4096
const MAX_VALUE_LENGTH = 500

const cookieOptions = {
    expires: COOKIE_EXPIRES,
    domain: getDomain(),
    sameSite: 'Lax' as const,
}

const sanitizeValue = (value: string): string => {
    let sanitized = value.trim().slice(0, MAX_VALUE_LENGTH)
    sanitized = sanitized.replace(/[;\s,]/g, '_').replace(/[^\w-.]]/g, '')
    return encodeURIComponent(sanitized)
}

const getInitialUTMState = (): UTMState => {
    const defaultState: UTMState = {
        current: {},
        first: {},
    }

    try {
        const cookieValue = Cookies.get(COOKIE_NAME)
        return cookieValue ? JSON.parse(cookieValue) : defaultState
    } catch (error) {
        console.warn('Failed to parse UTM cookie:', error)
        return defaultState
    }
}

export const getUTMTracking = (): UTMTracking | undefined => {
    const state = getInitialUTMState()
    const result: UTMTracking = {}

    // Only include non-null values
    UTM_PARAMS.forEach((param) => {
        if (state.current[param]) {
            result[param] = state.current[param]
        }
    })

    // Return undefined if no UTM parameters are present
    return Object.keys(result).length > 0 ? result : undefined
}

export const getFirstUTMTracking = (): UTMTracking | undefined => {
    const state = getInitialUTMState()
    const result: UTMTracking = {}

    // Only include non-null values
    UTM_PARAMS.forEach((param) => {
        if (state.first[param]) {
            result[param] = state.first[param]
        }
    })

    // Return undefined if no UTM parameters are present
    return Object.keys(result).length > 0 ? result : undefined
}

const saveUTMState = (state: UTMState) => {
    try {
        const serialized = JSON.stringify(state)
        if (serialized.length <= MAX_COOKIE_LENGTH) {
            Cookies.set(COOKIE_NAME, serialized, cookieOptions)
        } else {
            console.warn('UTM cookie value too long, value was truncated')
            // If needed, implement a more sophisticated truncation strategy
        }
    } catch (error) {
        console.warn('Failed to save UTM state:', error)
    }
}

const UTMTrackerInner = () => {
    const searchParams = useSearchParams()

    useEffect(() => {
        const currentState = getInitialUTMState()
        let hasChanges = false

        UTM_PARAMS.forEach((param) => {
            const value = searchParams?.get(`utm_${param}`)
            if (!value) return

            // Update current value
            currentState.current[param] = sanitizeValue(value)
            console.log(`Setting current utm_${param} to: ${value}`)

            // Set first value if not already set
            if (!currentState.first[param]) {
                currentState.first[param] = sanitizeValue(value)
                console.log(`Setting first utm_${param} to: ${value}`)
            }

            hasChanges = true
        })

        if (hasChanges) {
            saveUTMState(currentState)
        }

        // const utmtr = Cookies.get(COOKIE_NAME)
        // console.debug('Current utmtr cookie value:', utmtr)
    }, [searchParams])

    return null
}

const UTMTracker = () => {
    return (
        <Suspense>
            <UTMTrackerInner />
        </Suspense>
    )
}

export default UTMTracker
