import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { UTMTracking } from '../initialize/utmTracker'
import { getCurrency } from 'locale-currency'
interface UserPreferences {
    accepted_terms_popup: boolean
    long_file_warning_shown: boolean
    onboarding_complete: boolean
}

export type UserPreferenceKey = keyof UserPreferences

export interface Subscription {
    stripe_id?: string
    stripe_email?: string
    subscription_id?: string
    subscription_item_id?: string
    subscription_price_id?: string //price id
    subscription_status?: string
    subscription_start?: number
    subscription_end?: number
    subscription_cancel_at?: number
    subscription_plan_name?: string
    credits?: number
    preferences: UserPreferences
    ga_client_id?: string
    is_vip?: boolean
    vip_days?: number
    vip_monthly_credits?: number
    vip_start?: string
}

interface CurrencyOptions {
    unit_amount: number
}
export interface Price {
    id: string
    lookup_key: string
    credits: number
    credits_trial: number
    product_name: string
    interval: string
    unit_amount: number
    currency_options?: { [key: string]: CurrencyOptions }
}

type TrackingParams = {
    ga_client_id?: string
    ga_session_id?: string
    fbc?: string
    fbp?: string
    utm_first?: UTMTracking
    utm_current?: UTMTracking
}

export const status_key: { [key: string]: string } = {
    incomplete: 'Incomplete',
    incomplete_expired: 'Incomplete - Expired',
    trialing: 'Trial',
    active: 'Active',
    past_due: 'Past Due',
    canceled: 'Canceled',
    unpaid: 'Unpaid',
    error: 'Error',
}

export function getLocaleCurrency() {
    const locale = typeof window !== 'undefined' ? navigator.language : 'en-US'
    try {
        console.log('getLocaleCurrency: init, ', 'locale:', locale, 'currency', getCurrency(locale).toLowerCase())
        const currency = getCurrency(locale).toLowerCase() || 'usd'

        // Get the symbol using the actual currency
        const symbol =
            new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: currency.toUpperCase(),
            })
                .formatToParts(0)
                .find((part) => part.type === 'currency')?.value || '$'

        return {
            code: currency,
            symbol,
        }
    } catch {
        return {
            code: 'usd',
            symbol: '$',
        }
    }
}

export const fetchSubscription = async (token: string, trackingParams: TrackingParams): Promise<Subscription | null> => {
    console.log('fetchSubscription: init, ', 'tracking params:', trackingParams)
    try {
        const response = await axios.post<Subscription>(`${process.env.NEXT_PUBLIC_API_URL}/get-subscription`, trackingParams, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        console.error('Error fetching subscription:', error)
        Sentry.captureException(error)
        return null
    }
}

export const fetchPrices = async (): Promise<Price[] | null> => {
    try {
        const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/retrieve-prices`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {},
        })
        console.log('Active Prices Fetched:', response.data)
        return response.data
    } catch (error) {
        console.error('Error fetching prices:', error)
        Sentry.captureException(error)
        return null
    }
}

// export const fetchAllPrices = async (): Promise<Price[] | null> => {
//     try {
//         const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/retrieve-all-prices`, {
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             params: {},
//         })
//         // console.log("prices:",response.data)
//         return response.data
//     } catch (error) {
//         console.error('Error fetching prices:', error)
//         Sentry.captureException(error)
//         return null
//     }
// }
