import Cookies from 'js-cookie'


export const getDomain = () => {
    if (typeof window === 'undefined') return ''

    const hostname = window.location.hostname

    // Handle special cases
    if (hostname === 'localhost' || /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
        return ''
    }

    // Start with the full hostname
    let domain = hostname
    let parts = domain.split('.')

    // Try setting a test cookie on progressively shorter domains
    while (parts.length > 1) {
        try {
            const testDomain = `.${parts.join('.')}`
            const testCookie = `test_cookie_${Date.now()}`

            // Attempt to set a test cookie with js-cookie
            Cookies.set(testCookie, '1', {
                domain: testDomain,
                path: '/',
                expires: 1 / 24 / 60, // 1 minute
            })

            // If we can read the cookie, this domain works
            const cookieValue = Cookies.get(testCookie)
            if (cookieValue) {
                // Clean up test cookie
                Cookies.remove(testCookie, {
                    domain: testDomain,
                    path: '/',
                })
                return testDomain
            }
        } catch (e) {
            // If setting cookie fails, try a shorter domain
        }

        // Remove the leftmost part and try again
        parts = parts.slice(1)
    }

    // If no broader domain works, return the full hostname
    return hostname
}